import Image from 'next/image';
import Link from 'next/link';
import { Button, Tab } from 'react-bootstrap';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Swiper as swipe } from 'swiper';
import { TransformationsProps } from '@/utils/types';
import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
SwiperCore.use([Navigation]);

interface TransformationProps {
  data: Array<any>;
  tab: string;
  change: string;
}

const Transformation = ({ data, tab, change }: TransformationProps) => {
  const swiperRef = useRef(null);
  const swiper = useSwiper();
  const [currentPosition, setCurrentPosition] = useState(0);
  const [swiperHeight, setSwiperHeight] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<swipe | null>(null);

  useEffect(() => {
    resetSwiper();
  }, [change, swiper]);

  useEffect(() => {
    const swiperWrapper = document.getElementsByClassName(
      'swiper-wrapper'
    )[0] as HTMLElement;
    if (swiperWrapper) {
      const offsetHeight = swiperWrapper.offsetHeight;
      setSwiperHeight(offsetHeight);
    }
  }, [swiperRef]);

  const resetSwiper = () => {
    if ((swiperRef.current as any)?.swiper) {
      (swiperRef.current as any)?.swiper.slideTo(0);
      setCurrentPosition(0);
    }
  };

  const handlePrevButtonClick = () => {
    swiper?.slidePrev();
  };

  const handleNextButtonClick = () => {
    swiper?.slideNext();
  };

  const handleItemClick = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  return (
    <Tab.Pane eventKey={tab}>
      <Swiper
        ref={swiperRef}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        spaceBetween={30}
        slidesPerView='auto'
        loop={false}
        onSlideChange={(swiper) => setCurrentPosition(swiper.activeIndex)}
        navigation={{
          prevEl: `.prev-button${tab}`,
          nextEl: `.next-button${tab}`,
        }}
      >
        {data.map((item: TransformationsProps, index: number) => (
          <SwiperSlide
            onClick={() => handleItemClick(index)}
            key={index.toString()}
          >
            <div
              className='box pointer'
              style={{
                height: swiperHeight || '100%',
              }}
            >
              <div>
                <h5 className='mb-3'>{item.title}</h5>
                <p className='normal-text mb-2-rem-fixed'>{item.descr}</p>
                <ul>
                  {item.list.map((list: string, index: number) => (
                    <li key={index}>{list}</li>
                  ))}
                </ul>
              </div>
              <div
                className='transformation-img-container'
                style={{ position: swiperHeight ? 'absolute' : 'unset' }}
              >
                <Link href={item.link}>
                  See {item.title} Solutions
                  <Image
                    src='/assets/icons/arrow-right-circle.svg'
                    width={16}
                    height={16}
                    priority={false}
                    alt={`${item.title} solutions`}
                    style={{ objectFit: 'contain' }}
                    className='img-fluid ms-2'
                  />
                </Link>
                <Image
                  src={item.image}
                  width={696}
                  height={264}
                  priority={false}
                  alt={item.title}
                  style={{
                    objectFit: 'cover',
                  }}
                  className='img-fluid transformation-img'
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='mt-2-rem text-end text-md-start button-transformation'>
        <Button
          type='button'
          className={
            currentPosition === 0
              ? `prev-button${tab} prev-button me-md-2 disabled`
              : `prev-button${tab} prev-button me-md-2`
          }
          onClick={handlePrevButtonClick}
        >
          <Image
            src='/assets/icons/arrow_right_new.png'
            width={16}
            height={16}
            priority={false}
            alt='application development solutions'
            style={{ objectFit: 'contain' }}
            className='img-fluid'
          />
        </Button>
        <Button
          type='button'
          className={
            data.length - 1 === currentPosition
              ? `next-button${tab} next-button ms-md-2 disabled`
              : `next-button${tab} next-button ms-md-2`
          }
          id={`${currentPosition.toString()}`}
          onClick={handleNextButtonClick}
        >
          <Image
            src='/assets/icons/arrow_right_new.png'
            width={16}
            height={16}
            priority={false}
            alt='application development solutions'
            style={{ objectFit: 'contain' }}
            className='img-fluid'
          />
        </Button>
      </div>
    </Tab.Pane>
  );
};

export default Transformation;
